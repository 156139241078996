import React from 'react'
import { withTranslation } from 'react-i18next'
import Slider from 'react-slick'

import I18n from '../components/i18n'
import Layout from '../components/layout'
import SEO from '../components/seo'

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 400,
  centerMode: true,
  centerPadding: '120px',
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        centerPadding: '60px'
      }
    },
    {
      breakpoint: 480,
      settings: {
        centerMode: false
      }
    }
  ]
}

const CashewNutsPage = ({ t }) => {
	return (
		<Layout>
			<SEO title="Home" keywords={[`agriculture`, `cultivate`, `japan`, `vietnam`, `pepper`]} />
			<div className="page">
				<div className="container grid-lg">
					<h1 className="page-title">{t('cashew_nuts.title')}</h1>
					<p>{t('cashew_nuts.sentence1')}</p>
					<p>{t('cashew_nuts.sentence2')}</p>
					<p>{t('cashew_nuts.sentence3')}</p>
					<p>{t('cashew_nuts.sentence4')}</p>
					<p>{t('cashew_nuts.sentence5')}</p>
					<p>{t('cashew_nuts.sentence6')}</p>
					<p>{t('cashew_nuts.sentence7')}</p>
					<p>{t('cashew_nuts.sentence8')}</p>
					<p>{t('cashew_nuts.sentence9')}</p>
					<p>{t('cashew_nuts.sentence10')}</p>
					<p>{t('cashew_nuts.sentence11')}</p>
					<p>{t('cashew_nuts.sentence12')}</p>
					<p>{t('cashew_nuts.sentence13')}</p>

					<p>{t('cashew_nuts.sentence14')}</p>
					<div className="columns">
						<div className="column col-6 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_co_mai_1.jpg')})`
							}}></div>
						</div>
						<div className="column col-6 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_co_mai_2.jpg')})`
							}}></div>
						</div>
					</div>

					<p>{t('cashew_nuts.sentence15')}</p>
					<div className="columns">
						<div className="column col-6 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_vuon_1.jpg')})`
							}}></div>
						</div>
						<div className="column col-6 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_vuon_2.jpg')})`
							}}></div>
						</div>
						<div className="column col-6 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_vuon_3.jpg')})`
							}}></div>
						</div>
						<div className="column col-6 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_vuon_4.jpg')})`
							}}></div>
						</div>
					</div>

					<p>{t('cashew_nuts.sentence16')}</p>
					<p>{t('cashew_nuts.sentence17')}</p>
					<p>{t('cashew_nuts.sentence18')}</p>
					<div className="columns">
						<div className="column col-6 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_dieu_1.jpg')})`
							}}></div>
						</div>
						<div className="column col-6 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_dieu_2.jpg')})`
							}}></div>
						</div>
					</div>

					<p>{t('cashew_nuts.sentence19')}</p>
					<div className="columns">
						<div className="column col-6 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_dieu_3.jpg')})`
							}}></div>
						</div>
						<div className="column col-6 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_dieu_4.jpg')})`
							}}></div>
						</div>
					</div>

					<p>{t('cashew_nuts.sentence20')}</p>
					<div className="columns">
						<div className="column col-4 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_td_1.jpg')})`
							}}></div>
						</div>
						<div className="column col-4 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_td_2.jpg')})`
							}}></div>
						</div>
						<div className="column col-4 col-xs-12">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_td_3.jpg')})`
							}}></div>
						</div>
					</div>

					<p>{t('cashew_nuts.sentence21')}</p>


          <Slider {...sliderSettings}>
            {
              [
                {
                  id: 1,
                  url: require('../images/cashew_nuts_tieu_xanh_0.jpg'),
                },
                {
                  id: 2,
                  url: require('../images/cashew_nuts_tieu_xanh_1.jpg'),
                },
                {
                  id: 3,
                  url: require('../images/cashew_nuts_tieu_xanh_2.jpg'),
                },
                {
                  id: 4,
                  url: require('../images/cashew_nuts_tieu_xanh_3.jpg'),
                },
                {
                  id: 5,
                  url: require('../images/cashew_nuts_tieu_xanh_4.jpg'),
                }
              ].map(item => (
                <div className="joc-item" key={item.id}>
                  <div className="joc-item-img" style={{ backgroundImage: `url(${item.url})` }}>
                  </div>
                </div>
              ))
            }
          </Slider>

					<div className="columns">
						<div className="column col-6 col-xs-6">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_nong_cu_1.jpg')})`
							}}></div>
						</div>
						<div className="column col-6 col-xs-6">
							<div className="img-as-bg" style={{
								backgroundImage: `url(${require('../images/cashew_nuts_nong_cu_2.jpg')})`
							}}></div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default withTranslation()(CashewNutsPage)
